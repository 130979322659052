import React, { createContext, useState } from "react"

import Navbar from "./Navbar"

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/Font.css"
import "../styles/Global.css"

export const typeLogoContext = createContext()

const Layout = props => {
  const [typeLeftLogo, setLeftLogo] = useState("3") // depending on the number will be its color
  const [typeRightLogo, setRightLogo] = useState("1") // depending on the number will be its color

  const [typeArrowLogo_mobile, setArrowLogo_mobile] = useState("2") // depending on the number will be its color (mobile)
  const [typeRightLogo_mobile, setRightLogo_mobile] = useState("1") // depending on the number will be its color (mobile)
  const [typeCenterLogo_mobile, setCenterLogo_mobile] = useState("3") // depending on the number will be its color (mobile)

  return (
    <typeLogoContext.Provider
      value={{
        setLeftLogo,
        setRightLogo,
        setArrowLogo_mobile,
        setRightLogo_mobile,
        setCenterLogo_mobile,
      }}
    >
      {/* <div className="row no-gutters">
        <div className="col">
          <Navbar
            typeLeftLogo={typeLeftLogo}
            typeRightLogo={typeRightLogo}
            typeArrowLogo_mobile={typeArrowLogo_mobile}
            typeRightLogoMobile={typeRightLogo_mobile}
            typeCenterLogo_mobile={typeCenterLogo_mobile}
            isMobile={props.isMobile}
            mobileLight={props.mobileLight}
          />
        </div>
      </div> */}

      <div className="row no-gutters">
        <div className="col">
          <main>{props.children}</main>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col">
          <footer>
            <script
              src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
              integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
              crossOrigin="anonymous"
            ></script>
            <script
              src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
              integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
              crossOrigin="anonymous"
            ></script>
            <script
              src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
              integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
              crossOrigin="anonymous"
            ></script>
          </footer>
        </div>
      </div>
    </typeLogoContext.Provider>
  )
}

export default Layout
