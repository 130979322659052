import React, {useState, Fragment, useEffect } from 'react';
import {Link} from 'gatsby';
import { MDBNavbar, MDBNavbarBrand, MDBHamburgerToggler } from 'mdbreact';

import "../styles/Navbar.css"
import ImgMenu from "./ImgMenu"
import "../styles/ImgMenu.css"

import logo_blue from "../../assets/images/logos/logo_blue.png"
import logo_white from "../../assets/images/logos/logo_white.png"
import logo_color from "../../assets/images/logos/logo_color.png"
import arrow_black from "../../assets/images/icons/arrow_black.png"
import arrow_color from "../../assets/images/icons/arrow_color.png"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NavbarPage = (props) => {
  // console.log(props.isMobile)
  const [isCollapse, setIsCollapse] = useState(false);
  let arrow = null;
  const [black, setBlack] = useState("#000");

  arrow = <img src={arrow_color} alt="arrow" className="arrow_nav"></img>

  const goBack = (e) => {  //return to the previous page
    e.preventDefault();
    window.location.href.includes('#') ? window.history.go(-2) : window.history.go(-1);
  }

  useEffect(() => {
    if(props.light) {
      setBlack("#fff");
    } else {
      setBlack("#000");
    }
  }, [props.light])

  return (

    <Fragment>
      {props.casaCorona ? (
        props.isMobile ? (
          <>
            <MDBNavbar className="mainNavbarCC" light id="navbar">
              <div className="navCont pl-0">
                  <div className="logo_nav_col col-xs-4">
                    <MDBNavbarBrand className="buttons-navbar">
                      <AniLink cover direction="down" duration={.5} bg="#ffc311" to='/'>
                        { isCollapse ? <img alt="logo" src={logo_white} className="logo_navCC col-xs-4"/>
                                    : props.mobileLight ? <img alt="logo" src={logo_white} className="logo_navCC col-xs-4"/>
                                    :(<img alt="logo" src={props.light ? logo_white : logo_color} className="logo_navCC col-xs-4"/> )}
                      </AniLink>
                    </MDBNavbarBrand>
                  </div>
              </div>
                  <div className="hambur col-xs-4">
                  {isCollapse ? <MDBHamburgerToggler className="buttons-navbar" color="#fff" id="hamburger1" onClick={()=> {setIsCollapse(!isCollapse)}} /> 
                              : <MDBHamburgerToggler className="buttons-navbar" color={black} id="hamburger1" onClick={()=> {setIsCollapse(!isCollapse)}} /> }
              </div>
            </MDBNavbar>
            <ImgMenu light={props.light} isOpen={isCollapse}/>
          </>
        ):(
          <>
            <MDBNavbar className="mainNavbarCCD" light id="navbar">
              <div className="navContD pl-0">
                  <div className="logo_nav_col col-xs-4">
                    <MDBNavbarBrand className="buttons-navbar">
                      <AniLink cover direction="down" duration={.5} bg="#ffc311" to='/'>
                        { props.mobileLight ? <img alt="logo" src={logo_white} className="logo_navCC col-xs-4"/>
                                    :(<img alt="logo" src={props.light ? logo_white : logo_color} className="logo_navCC col-xs-4"/> )}
                      </AniLink>
                    </MDBNavbarBrand>
                  </div>
              </div>
              <div className="hambur col-xs-4">
              {isCollapse ? <MDBHamburgerToggler className="buttons-navbar" color="#fff" id="hamburger1" onClick={()=> {setIsCollapse(!isCollapse)}} /> 
                          : <MDBHamburgerToggler className="buttons-navbar" color="white" id="hamburger1" onClick={()=> {setIsCollapse(!isCollapse)}} /> }
              </div>
            </MDBNavbar>
            <ImgMenu light={props.light} isOpen={isCollapse}/>
        </>
        )
        
      ):(
        <>
         <MDBNavbar className="mainNavbar" light id="navbar">
        <div className="container-fluid pl-0">
            {props.isMobile ? <div style={{width:"100px"}}/> : null}
            <div className="logo_nav_col col-xs-4">
              <MDBNavbarBrand className="buttons-navbar">
                <AniLink cover direction="down" duration={.5} bg="#ffc311" to='/'>
                  { isCollapse && props.isMobile ? <img alt="logo" src={logo_white} className="logo_nav col-xs-4"/>
                              : props.mobileLight ? <img alt="logo" src={logo_white} className="logo_nav col-xs-4"/>
                              :(<img alt="logo" src={props.light ? logo_white : logo_color} className="logo_nav col-xs-4"/> )}
                </AniLink>
              </MDBNavbarBrand>
            </div>
            <div className="col-xs-4">
            {isCollapse ? <MDBHamburgerToggler className="buttons-navbar" color="#fff" id="hamburger1" onClick={()=> {setIsCollapse(!isCollapse)}} /> 
                        : <MDBHamburgerToggler className="buttons-navbar" color={black} id="hamburger1" onClick={()=> {setIsCollapse(!isCollapse)}} /> }
          </div>
        </div>
      </MDBNavbar>
      <ImgMenu light={props.light} isOpen={isCollapse}/>
        </>
      )}
     
    </Fragment>
    
    );

}

export default NavbarPage;