import React from 'react'

import '../styles/Footer.css';
import arrow from '../../assets/images/icons/arrow_color.png'
import icon_instagram from '../../assets/images/icons/instagram.svg'
import icon_facebook from '../../assets/images/icons/facebook.svg'

const Footer = ({handlerArrow}) => (

    <div className="row footer">
        <div className="col-md-1 p-0" id='miCorona-icons'>
            <div className="footerIcon" onClick={() => {
                window.dataLayer.push({
                    'event' : 'GAEvent',
                    'event_category': 'Social',
                    'event_action': 'Click',
                    'event_label': 'https://www.instagram.com/corona_argentina',
                    'interaction': 'True',
                    'component_name': 'boton_instagram',
                    'element_text': 'Instagram',
                });
            }}>
                <a href="https://www.instagram.com/corona_argentina" target="_blank"> <img src={icon_instagram} className="footerInstagram"/></a>
            </div>
            <div className="footerIcon" onClick={() => {
                window.dataLayer.push({
                    'event' : 'GAEvent',
                    'event_category': 'Social',
                    'event_action': 'Click',
                    'event_label': 'https://www.facebook.com/cervezacorona',
                    'interaction': 'True',
                    'component_name': 'boton_facebook',
                    'element_text': 'Facebook',
                });
            }}>
                <a href="https://www.facebook.com/cervezacorona" target="_blank"><img src={icon_facebook} className="footerFacebook"/></a>
            </div>
        </div>
        <div className="col-md-11 d-flex justify-content-between align-items-end">
            {/* arrowLeft */}
            <div href="/" id="miCorona-arrowLeft" onClick={e => handlerArrow(e,'prev')} className="button">
                <img src={arrow} className="footerArrowLeft"/>
            </div>
            {/* arrowRight */}
            <div href="/" onClick={e => handlerArrow(e,'next')} className="button">
                <img src={arrow} className="footerArrowRight"/>
            </div>
        </div>
    </div>

)

export default Footer;